import * as React from "react";

import { Layout } from "../components/Layout";
import { Head } from "../components/Head";

import {
  SectionContainer,
  SectionWrapper
} from "../common/Section";

import { ErrorHero } from "../components/Hero";


interface Props {}


const NotFoundPage: React.FC<Props> = () => {
  return (
    <Layout>
      <Head
        title="404: Not Found"
      />

      <SectionWrapper>
        <SectionContainer>
          <ErrorHero
            heading="404: Page Not Found"
            description={[
              "The page that you have requested doesn't exist."
            ]}
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export default NotFoundPage;
